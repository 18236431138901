import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { NewPasswordRequestModel } from '../../../models/account/new-password.models';

export const newPasswordFeaturesActions = createActionGroup({
  source: 'New Password',
  events: {
    'Load Password Policy': emptyProps(),
    'Verify Reset Password Token': props<{ userId: string; token: string }>(),
    'Set New Password': props<Omit<NewPasswordRequestModel, 'userId' | 'token' | 'recaptchaToken'>>(),
    'Set Token': props<{ token: NewPasswordRequestModel['token'] }>(),
    'Set User Id': props<{ userId: NewPasswordRequestModel['userId'] }>(),
    'ReCaptcha Failed': emptyProps(),
    'Reset password': emptyProps(),
  },
});
