import { createFeatureSelector, createSelector } from '@ngrx/store';
import { NewPasswordState } from './new-password.reducer';

export const newPasswordState = createFeatureSelector<NewPasswordState>('newPasswordFeature');

export const selectNewPasswordActionLoginState = createSelector(newPasswordState, (state) => state.loadingActionState);
export const selectNewPasswordErrorMessage = createSelector(newPasswordState, (state) => state.errorMessage);
export const selectNewPasswordUserId = createSelector(newPasswordState, (state) => state.userId);
export const selectNewPasswordToken = createSelector(newPasswordState, (state) => state.token);
export const selectPasswordPolicyStrength = createSelector(newPasswordState, (state) => state.passwordPolicy);
export const selectHasValidToken = createSelector(newPasswordState, (state) => state.hasValidToken);
export const selectLoading = createSelector(newPasswordState, (state) => state.loading);
export const selectUserEmail = createSelector(newPasswordState, (state) => state.userEmail);
export const selectResentLoading = createSelector(newPasswordState, (state) => state.resentLoading);
